import { RootState } from "app/store";
import { TaskData } from "features/tasks/types";
import { CategoryView } from "features/environment/types";
import { selectActiveVenueId } from "app/appSlice";
import { selectCategory } from "features/tasks/tasksSlice";
import { categoryViewSchema } from "./zodFormConfig";
import { selectVenueId } from "features/venue/venueSlice";

let _venueId: number | undefined;
let _taskConfigs: Record<number, CategoryView> = {};

export function resetTaskFormConfigState(venueId: number) {
    _venueId = venueId;
    _taskConfigs = {};
}

export default function getTaskFormConfig(
    task: TaskData,
    state: RootState,
    venueId?: number
): CategoryView | undefined {
    if (!venueId) venueId = selectActiveVenueId(state) || selectVenueId(state);

    if (!venueId) return void 0;

    if (venueId !== _venueId) {
        resetTaskFormConfigState(venueId);
    }

    let config = _taskConfigs[task.id];
    if (config) {
        return config;
    }

    const category = selectCategory(state, task.category_id);

    if (
        category &&
        category.configuration &&
        !Array.isArray(category.configuration)
    ) {
        config = {
            name: category.name,
            ...category.configuration,
        };
    }

    if (task.configuration && !Array.isArray(task.configuration)) {
        config = {
            ...config,
            taskView: { ...task.configuration },
        };
    }

    if (!config.name && category) {
        config.name = category.name;
    } else if (!config.name && task.name) {
        config.name = task.name;
    }

    const result = categoryViewSchema.safeParse(config);
    _taskConfigs[task.id] = config;
    if (!result.success) {
        console.error(
            "task %o categoryViewSchema error: %o",
            task,
            result.error.issues
        );
    }

    return config;
}
