import { RootState, getStore } from "app/store";
import { appServiceApi } from "services/appService";
import { appSelect, appDispatch, appSelectArg } from "app/util";
import { TaskData, TaskPerformedData, DiaryEntry } from "./types";
import {
    setCategories,
    setTasksPerformed,
    selectTasks,
    selectTasksPerformedEntities,
    setTasks,
    setTask,
    setTaskPerformed,
    tasksSlice,
    selectTask,
    setTaskDiaryEntries,
    addTaskDiaryEntries,
    selectDiaryEntries,
    DEFAULT_DIARY_CUTOFF,
    removeTaskDiaryEntriesByDate,
} from "./tasksSlice";
import getScheduleWorker from "./getScheduleWorker";
import type { AppStartListening } from "app/listeners";
import Scheduler from "./Scheduler";
import {
    selectActiveVenueId,
    selectDiaryEntryQueue,
    setServerError,
} from "app/appSlice";
import getTaskFormConfig, {
    resetTaskFormConfigState,
} from "features/tasks/form/getTaskFormConfig";

let scheduleDataProcessing = false;
let scheduleDataUpdated = false;
let scheduleUpdatedTasks: TaskData[] | undefined;

const scheduleWorker = getScheduleWorker();
scheduleWorker.addEventListener("message", (e: MessageEvent<TaskData[]>) => {
    const tasks = e.data;
    getStore().dispatch(setTasks(tasks));
    scheduleDataProcessing = false;
    if (scheduleDataUpdated) {
        updateTaskPerformedData(scheduleUpdatedTasks);
    }
    const venueId = selectActiveVenueId(getStore().getState());
    if (venueId) {
        resetTaskFormConfigState(venueId);
    }
});

export const startTasksListening = (startListening: AppStartListening) => {
    startListening({
        matcher: appServiceApi.endpoints.getTasks.matchFulfilled,
        effect: (action, listenerApi) => {
            // Don't set data if not logged in to a venue or the
            // tasks venue is not the active venue
            const venueId = selectActiveVenueId(listenerApi.getState());
            if (!venueId || action.meta.arg.originalArgs !== venueId) return;

            const tasks = action.payload;
            listenerApi.dispatch(setServerError(["tasks", void 0]));
            updateTaskPerformedData(tasks);

            fetchDiaryEntries(tasks, listenerApi.getState());
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.getTasks.matchRejected,
        effect: (action, listenerApi) => {
            if (!action.payload) return;

            const status = action.payload?.status;
            const data = action.payload?.data;
            const datetime = new Date().toISOString();
            const error = {
                httpCode: Number(status),
                data,
                datetime,
            };
            listenerApi.dispatch(setServerError(["tasks", error]));
        },
    });
};

export const startCategoriesListening = (startListening: AppStartListening) => {
    startListening({
        matcher: appServiceApi.endpoints.getCategories.matchFulfilled,
        effect: (action, listenerApi) => {
            const categories = action.payload;
            listenerApi.dispatch(setCategories(categories));
        },
    });
};

export const fetchDiaryEntries = (tasks: TaskData[], state: RootState) => {
    const venueId = selectActiveVenueId(state);
    if (!venueId) return;

    let fetchTasks: TaskData[] = [];
    for (let task of tasks) {
        let config = getTaskFormConfig(task, state, venueId);
        if (config?.taskView?.showBatchNumber) {
            fetchTasks.push(task);
        }
    }

    if (fetchTasks.length > 0) {
        getStore().dispatch(
            appServiceApi.endpoints.getTaskDiaries.initiate(
                { tasks: fetchTasks, venueId },
                {
                    forceRefetch: true,
                }
            )
        );
    }
};

export const startTaskDiaryEntriesListening = (
    startListening: AppStartListening
) => {
    startListening({
        matcher: appServiceApi.endpoints.getTaskDiaries.matchFulfilled,
        effect: (action, listenerApi) => {
            const diaries = action.payload;
            let taskDiaries: Record<number, DiaryEntry[]> = {};
            for (let diary of diaries) {
                if (!taskDiaries[diary.taskId]) {
                    taskDiaries[diary.taskId] = [];
                }
                taskDiaries[diary.taskId].push(diary);
            }

            for (let taskId in taskDiaries) {
                listenerApi.dispatch(
                    setTaskDiaryEntries([Number(taskId), taskDiaries[taskId]])
                );
            }
            const queuedDiaries = appSelect(selectDiaryEntryQueue);
            listenerApi.dispatch(addTaskDiaryEntries(queuedDiaries));
            // Remove any old diary entries that aren't useful.
            listenerApi.dispatch(
                removeTaskDiaryEntriesByDate(DEFAULT_DIARY_CUTOFF)
            );
        },
    });
};

export const startVenueStateListening = (startListening: AppStartListening) => {
    startListening({
        matcher: appServiceApi.endpoints.getVenueData.matchFulfilled,
        effect: (action, listenerApi) => {
            const venueData = action.payload;
            listenerApi.dispatch(setTasksPerformed(venueData.performed));

            updateTaskPerformedData();
        },
    });
};

const PROVEN_UPDATE_TIME_LIMIT = 5 * 60000;
let _provenCountUpdates: Record<number, Date> = {};

function updateTaskPerformedData(
    tasks?: TaskData[],
    tasksPerformed?: Record<number, TaskPerformedData>
) {
    if (scheduleDataProcessing) {
        scheduleDataUpdated = true;
        if (tasks) scheduleUpdatedTasks = tasks;
        return;
    }
    scheduleUpdatedTasks = void 0;
    scheduleDataUpdated = false;
    scheduleDataProcessing = true;

    let currentTasks = appSelect(selectTasks);
    if (!tasks) {
        tasks = currentTasks;
    } else {
        // Proven updates may not yet have been processed on the server,
        // so update fetched tasks if required.
        let currentTasksById = currentTasks.reduce(
            (prevValue: { [key: number]: TaskData }, currentValue) => {
                prevValue[currentValue.id] = currentValue;

                return prevValue;
            },
            {}
        );
        let updatedTasks = [];
        for (let task of tasks) {
            let currentTask = currentTasksById[task.id];
            let overrideServerProvenCount = false;
            if (
                currentTask &&
                _provenCountUpdates.hasOwnProperty(currentTask.id)
            ) {
                let updateTime = _provenCountUpdates[currentTask.id];
                if (
                    new Date().getTime() - updateTime.getTime() <
                    PROVEN_UPDATE_TIME_LIMIT
                ) {
                    overrideServerProvenCount = true;
                } else {
                    delete _provenCountUpdates[currentTask.id];
                }
            }
            if (
                currentTask &&
                currentTask.proven_count > task.proven_count &&
                overrideServerProvenCount
            ) {
                let provenTask = {
                    ...task,
                    proven_count: currentTask.proven_count,
                };
                updatedTasks.push(provenTask);
            } else {
                updatedTasks.push(task);
            }
        }
        tasks = updatedTasks;
    }
    if (!tasksPerformed) {
        tasksPerformed = appSelect(selectTasksPerformedEntities);
    }

    const venueId = appSelect(selectActiveVenueId);
    const diaries = venueId
        ? appSelectArg(selectDiaryEntries, venueId)
        : undefined;

    scheduleWorker.postMessage({
        tasks,
        tasksPerformed,
        diaries,
    });
}

export const startDiariesAddedListening = (
    startListening: AppStartListening
) => {
    startListening({
        actionCreator: tasksSlice.actions.addDiaryEntries,
        effect: (action, listenerApi) => {
            let taskIds: number[] = [];
            for (let diaryEntry of action.payload) {
                if (taskIds.indexOf(diaryEntry.taskId) !== -1) continue;
                const task = appSelectArg(selectTask, diaryEntry.taskId);
                if (task) {
                    let proven = JSON.parse(
                        diaryEntry.information
                    ).hasOwnProperty("proven");
                    completeTask(task, proven);
                }
                taskIds.push(diaryEntry.taskId);
            }
        },
    });
};

export const completeTask = (task: TaskData, proven = false) => {
    const performed = Scheduler.getNowUtc();
    let lastDueCompleted: number | undefined;
    if (task.schedule) {
        const taskSchedule = new Scheduler(task);
        lastDueCompleted = taskSchedule.getLastDueUtc();
    } else {
        lastDueCompleted = Scheduler.getNowUtc();
    }

    task = {
        ...task,
        isPerformed: true,
        performed,
        lastDueCompleted,
    };

    if (proven) {
        task = {
            ...task,
            proven_count: task.proven_count + 1,
        };
        _provenCountUpdates[task.id] = new Date();
    }

    appDispatch(setTask(task));

    if (lastDueCompleted) {
        appDispatch(
            setTaskPerformed({
                taskId: task.id,
                performed,
                lastDueCompleted,
            })
        );
    } else {
        console.warn("Missing lastDueCompleted for new diary entry: %o", task);
    }
};

const listeners = [
    startCategoriesListening,
    startTasksListening,
    startVenueStateListening,
    startDiariesAddedListening,
    startTaskDiaryEntriesListening,
];
export default listeners;
