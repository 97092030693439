import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { useForm, UseFormRegister, UseFormHandleSubmit } from "react-hook-form";
import { QueryState, getQueryState } from "services/util";
import { selectShowEmailLogin } from "features/environment/envSlice";
import QrScanner from "qr-scanner";
import {
    loginType,
    selectTokenSource,
    setTokenSource,
    selectLoginType,
    setFernetToken,
    setLoginError,
    selectLoginError,
    selectShowLogin,
} from "./loginTokenSlice";
import type { tokenSource } from "./loginTokenSlice";
import { selectUrlsParams } from "app/appSlice";
import { appServiceApi } from "services/appService";
import { showRoute } from "app/routeListeners";

export function parseQrCodeData(
    data: string
): [fernetToken: string, tokenSource: tokenSource] | null {
    const regex = /.*\/(qr[cC]ode|migrate|link)\/(.+)$/;
    const match = regex.exec(data);

    return match ? [match[2], match[1].toLowerCase() as tokenSource] : null;
}

export type FormData = {
    username: string;
    password: string;
};

let _fernetTokenSet: Date | undefined;
// Number of seconds that must have passed since the last fernet token was set.
// This prevents login triggering multiple times when the QR code is scanned.
const _fernetExpiredTime = 3;
function fernetExpired(): boolean {
    if (!_fernetTokenSet) return true;

    const now = new Date();
    const diff = (now.getTime() - _fernetTokenSet.getTime()) / 1000;
    return diff > _fernetExpiredTime;
}

interface LoginTokenData {
    loginType: loginType;
    loginError?: string;
    passwordLoginState: QueryState | undefined;
    getVenuesQueryState: QueryState | undefined;
    showEmailLogin: boolean;
    fernetToken?: string;
    tokenSource?: tokenSource;
    showLogin: boolean;
    queryEmail?: string;
    queryPassword?: string;
    register: UseFormRegister<FormData>;
    handleSubmit: UseFormHandleSubmit<FormData>;
    handleCancel: () => void;
    loginWithPassword: () => void;
    processQrResult: (result: QrScanner.ScanResult) => void;
}

export default function useLoginToken(): LoginTokenData {
    const [hasSetFocus, setHasSetFocus] = useState(false);
    const [hasAutoLoggedIn, setHasAutoLoggedIn] = useState(false);
    const loginType = useAppSelector(selectLoginType) || "qrCode";
    const loginError = useAppSelector(selectLoginError);
    const showEmailLogin = useAppSelector(selectShowEmailLogin);
    const loginTokenSource = useAppSelector(selectTokenSource);
    const showLogin = useAppSelector(selectShowLogin);
    const queryEmail = useAppSelector(selectUrlsParams).email;
    const queryPassword = useAppSelector(selectUrlsParams).password;
    const dispatch = useAppDispatch();
    const { register, handleSubmit, watch } = useForm<FormData>();
    const username = watch("username", queryEmail);
    const password = watch("password", queryPassword);
    const credential = useMemo(() => {
        return {
            username,
            password,
        };
    }, [username, password]);
    const passwordLoginState = getQueryState(
        appServiceApi.endpoints.getBearerToken.useQueryState(credential)
    );
    const getVenuesQueryState = getQueryState(
        appServiceApi.endpoints.getVenues.useQueryState()
    );

    const processQrResult = useCallback(
        (result: QrScanner.ScanResult) => {
            if (!fernetExpired()) return;

            const [qrFernetToken, tokenSource] =
                parseQrCodeData(result.data) || [];
            if (qrFernetToken) {
                dispatch(setTokenSource(tokenSource));
                dispatch(setFernetToken(qrFernetToken));
                dispatch(setLoginError());
            } else {
                let error;
                if (tokenSource === "migrate") {
                    error =
                        "The migration security key could not be processed.";
                } else if (tokenSource === "qrcode") {
                    error = "Secure QR Code could not be processed.";
                } else {
                    error = "Secure link could not be processed.";
                }
                dispatch(setLoginError(error));
            }
            _fernetTokenSet = new Date();
        },
        [dispatch]
    );

    const loginWithPassword = useCallback(() => {
        dispatch(setLoginError());
        dispatch(setTokenSource());
        dispatch(
            appServiceApi.endpoints.getBearerToken.initiate(credential, {
                forceRefetch: true,
            })
        );
    }, [dispatch, credential]);

    const handleCancel = useCallback(() => {
        dispatch(showRoute("default"));
    }, [dispatch]);

    useEffect(() => {
        if (!hasSetFocus) {
            if (queryEmail) {
                document.getElementById("loginPassword")?.focus();
            } else {
                document.getElementById("loginEmail")?.focus();
            }
            setHasSetFocus(true);
        }
        if (!hasAutoLoggedIn && queryEmail && queryPassword) {
            loginWithPassword();
            setHasAutoLoggedIn(true);
        }
    }, [
        queryEmail,
        hasSetFocus,
        setHasSetFocus,
        hasAutoLoggedIn,
        queryPassword,
        setHasAutoLoggedIn,
        loginWithPassword,
    ]);

    return {
        loginType,
        loginError,
        passwordLoginState,
        getVenuesQueryState,
        showEmailLogin,
        tokenSource: loginTokenSource,
        showLogin,
        queryEmail,
        queryPassword,
        register,
        handleSubmit,
        handleCancel,
        loginWithPassword,
        processQrResult,
    };
}
