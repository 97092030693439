import {
    createSlice,
    createEntityAdapter,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Supplier } from "./types";

export interface SuppliersState {
    suppliers: EntityState<Supplier, number>;
}

const suppliersAdapter = createEntityAdapter<Supplier>();

const initialState: SuppliersState = {
    suppliers: suppliersAdapter.getInitialState(),
};

export const suppliersSlice = createSlice({
    name: "suppliers",
    initialState,
    reducers: {
        setSuppliers: (state, action: PayloadAction<Supplier[]>) => {
            suppliersAdapter.setAll(state.suppliers, action.payload);
        },
        resetState: (state, action: PayloadAction) => {
            return initialState;
        },
    },
});

export const { setSuppliers } = suppliersSlice.actions;

const supplierSelectors = suppliersAdapter.getSelectors();

export const selectSuppliers = (state: RootState) =>
    supplierSelectors.selectAll(state.suppliers.suppliers);

export const selectHasSetData = (state: RootState) =>
    state.suppliers.suppliers !== initialState.suppliers;

export default suppliersSlice.reducer;
