import {
    createSlice,
    createEntityAdapter,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Staff } from "./types";

export interface StaffState {
    staff: EntityState<Staff, number>;
}

const staffAdapter = createEntityAdapter<Staff>();

const initialState: StaffState = {
    staff: staffAdapter.getInitialState(),
};

export const staffSlice = createSlice({
    name: "staff",
    initialState,
    reducers: {
        setStaff: (state: StaffState, action: PayloadAction<Staff[]>) => {
            staffAdapter.setAll(state.staff, action.payload);
        },
        resetState: (state, action: PayloadAction) => {
            return initialState;
        },
    },
});

export const { setStaff } = staffSlice.actions;

const staffSelectors = staffAdapter.getSelectors();

export const selectStaff = (state: RootState) =>
    staffSelectors.selectAll(state.staff.staff);

export const selectStaffWithSignoff = (state: RootState) =>
    staffSelectors
        .selectAll(state.staff.staff)
        .filter((staff) => staff.app_sign_off === 1);

export const selectHasSetData = (state: RootState) =>
    state.staff.staff !== initialState.staff;

export default staffSlice.reducer;
