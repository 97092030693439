import { AppDispatch, RootState } from "./store";
import {
    resetState,
    setActiveScreen,
    pushActiveScreen,
    DEFAULT_SCREEN,
    selectUrlsParams,
    selectActiveScreen,
} from "./appSlice";
import {
    setShowLogin,
    setLoginType,
    setResetPasswordCredential,
} from "features/loginToken/loginTokenSlice";
import { showRoute } from "./routeListeners";
import { setBearerToken } from "features/loginToken/loginTokenSlice";

export interface Route {
    url: string;
    setup?: (
        dispatch: AppDispatch,
        state: RootState,
        urlParams?: Record<string, any>,
        queryParams?: Record<string, any>
    ) => void;
}

const defaultRoute: Route = {
    url: "/",
    setup: (dispatch: AppDispatch) => {
        dispatch(setShowLogin(false));
        dispatch(setActiveScreen(DEFAULT_SCREEN));
    },
};

const routes = {
    default: defaultRoute,
    inviteSuccess: {
        url: "/login/invite/success",
        setup: (dispatch: AppDispatch) => {
            dispatch(setActiveScreen("inviteSuccess"));
        },
    },
    invite: {
        url: "/login/invite/:token",
        setup: (dispatch: AppDispatch) => {
            dispatch(resetState());
            dispatch(setActiveScreen("invite"));
        },
    },
    login: {
        url: "/login",
        setup: (dispatch: AppDispatch) => {
            dispatch(resetState());
            dispatch(setActiveScreen("login"));
        },
    },
    loginEmail: {
        url: "/login/email",
        setup: (dispatch: AppDispatch) => {
            dispatch(resetState());
            dispatch(setActiveScreen("login"));
            dispatch(setShowLogin(true));
            dispatch(setLoginType("password"));
        },
    },
    loginQrcode: {
        url: "/login/qrcode",
        setup: (dispatch: AppDispatch) => {
            dispatch(setActiveScreen("login"));
            dispatch(setShowLogin(true));
            dispatch(setLoginType("qrCode"));
        },
    },
    passwordReset: {
        url: "/login/password_reset",
        setup: (dispatch: AppDispatch) => {
            dispatch(setActiveScreen("passwordReset"));
        },
    },
    passwordResetInitiated: {
        url: "/login/password_reset/initiated",
        setup: (dispatch: AppDispatch) => {
            dispatch(setActiveScreen("passwordResetInitiated"));
        },
    },
    passwordResetToken: {
        url: "/login/password_reset/:profileId",
        setup: (
            dispatch: AppDispatch,
            state: RootState,
            urlParams: Record<string, any>
        ) => {
            let queryParams = selectUrlsParams(state);
            dispatch(resetState());
            dispatch(
                setResetPasswordCredential({
                    token: queryParams["reset"],
                    uuid: urlParams["profileId"],
                })
            );
            dispatch(showRoute("setPassword"));
        },
    },
    setPassword: {
        url: "/login/set_password",
        setup: (dispatch: AppDispatch, state: RootState) => {
            dispatch(resetState());
            let queryParams = selectUrlsParams(state);
            if ("token" in queryParams) {
                // This token is only valid for changing the password
                // and will get replaced after doing so
                let bearerToken = {
                    token: queryParams["token"],
                    expiresIn: 0,
                    refreshToken: "",
                };
                dispatch(setBearerToken(bearerToken));
            }
            dispatch(setActiveScreen("setPassword"));
        },
    },
    setPasswordSuccess: {
        url: "/login/set_password/success",
        setup: (dispatch: AppDispatch) => {
            dispatch(setActiveScreen("setPasswordSuccess"));
        },
    },
    changePassword: {
        url: "/profile/change_password",
        setup: (dispatch: AppDispatch, state: RootState) => {
            dispatch(pushActiveScreen("changePassword"));
        },
    },
    profile: {
        url: "/profile",
        setup: (dispatch: AppDispatch, state: RootState) => {
            const currentScreen = selectActiveScreen(state);
            if (currentScreen !== "profile") {
                dispatch(pushActiveScreen("profile"));
            }
        },
    },
    settings: {
        url: "/settings",
        setup: (dispatch: AppDispatch, state: RootState) => {
            const currentScreen = selectActiveScreen(state);
            if (currentScreen !== "settings") {
                dispatch(pushActiveScreen("settings"));
            }
        },
    },
    init: defaultRoute,
    due: defaultRoute,
    admin: defaultRoute,
    events: defaultRoute,
    tasks: defaultRoute,
    diary: defaultRoute,
    taskGroup: defaultRoute,
    checkList: defaultRoute,
    temperatureList: defaultRoute,
    task: defaultRoute,
};

export default routes;

export type RouteName = keyof typeof routes;
