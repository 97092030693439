import useLoginToken from "./useLoginToken";
import type { FormData } from "./useLoginToken";
import styles from "./Login.module.scss";
import Form from "react-bootstrap/Form";
import Button from "features/tasks/form/Button";
import loaderStyles from "app/Loader.module.css";
import LoginError from "./LoginError";
import QRCodeScanner from "./QRCodeScanner";
import LoginButtons from "./LoginButtons";

interface Props {
    loginError?: string;
}

export function Login(props: Props) {
    const {
        loginType,
        loginError,
        passwordLoginState,
        getVenuesQueryState,
        tokenSource,
        showLogin,
        queryEmail,
        queryPassword,
        register,
        handleSubmit,
        handleCancel,
        loginWithPassword,
        processQrResult,
    } = useLoginToken();

    const onSubmit = (data: FormData) => {
        loginWithPassword();
    };

    let loginComponent;
    if (showLogin) {
        if (loginType === "qrCode") {
            loginComponent = (
                <>
                    {!getVenuesQueryState?.isLoading && (
                        <QRCodeScanner
                            processQrResult={processQrResult}
                            getVenuesQueryState={getVenuesQueryState}
                        />
                    )}
                    {getVenuesQueryState?.isLoading && (
                        <div className={styles.loginLoader}>
                            <div className={loaderStyles.Loader}>
                                <img
                                    src="/ChompSpinner.svg"
                                    alt="Logging in..."
                                />
                            </div>
                        </div>
                    )}
                </>
            );
        } else {
            loginComponent = (
                <div className={styles.venueKeyForm}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                id="loginEmail"
                                defaultValue={queryEmail}
                                {...register("username")}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                defaultValue={queryPassword}
                                id="loginPassword"
                                {...register("password")}
                            />
                        </Form.Group>
                        <a
                            href="/login/password_reset"
                            className={styles.passwordReset}
                        >
                            Forgotten your password?
                        </a>
                        {!passwordLoginState?.isLoading && (
                            <>
                                <Button type="submit">Sign In</Button>

                                <Button
                                    onClick={handleCancel}
                                    className={styles.cancel}
                                >
                                    Choose another login method
                                </Button>
                            </>
                        )}
                        {passwordLoginState?.isLoading && (
                            <div className={styles.loginLoader}>
                                <div className={loaderStyles.Loader}>
                                    <img
                                        src="/ChompSpinner.svg"
                                        alt="Logging in..."
                                    />
                                </div>
                            </div>
                        )}
                    </Form>
                </div>
            );
        }
    } else {
        loginComponent = <LoginButtons />;
    }

    let loginErrorComponent;
    if ((loginError || props.loginError) && !getVenuesQueryState?.isLoading) {
        loginErrorComponent = (
            <LoginError
                loginError={loginError || props.loginError}
                tokenSource={tokenSource}
            />
        );
    }

    let loginScreen;
    if (getVenuesQueryState?.isLoading) {
        loginScreen = (
            <>
                <h2>
                    <img
                        src="/Chomp-Logo.png"
                        alt="Chomp"
                        className={styles.logo}
                    />
                </h2>
                <div className={styles.loginLoader}>
                    <div className={loaderStyles.Loader}>
                        <img src="/ChompSpinner.svg" alt="Logging in..." />
                    </div>
                </div>
            </>
        );
    } else {
        loginScreen = (
            <>
                <h2>
                    <img
                        src="/Chomp-Logo-simplified.png"
                        alt="Chomp"
                        className={styles.logo}
                    />
                </h2>

                {loginComponent}
                {loginErrorComponent}
            </>
        );
    }

    return <div className="loginScreen">{loginScreen}</div>;
}
