import type { AppStartListening } from "app/listeners";
import { appServiceApi } from "services/appService";
import {
    popActiveScreen,
    setServerError,
    selectActiveScreen,
    addAppToast,
} from "app/appSlice";
import { getServerError } from "services/appService";
import { showRoute } from "app/routeListeners";
import { setBearerToken } from "features/loginToken/loginTokenSlice";
import type { AppToast } from "app/types";

export const startSetPasswordListening = (
    startListening: AppStartListening
) => {
    startListening({
        matcher: appServiceApi.endpoints.setPassword.matchFulfilled,
        effect: (action, listenerApi) => {
            if (
                selectActiveScreen(listenerApi.getState()) === "changePassword"
            ) {
                const toast: AppToast = {
                    id: `change_password_success_` + new Date().getTime(),
                    title: `Your password has been changed successfully.`,
                    created: new Date().toISOString(),
                    type: "success",
                    permanent: false,
                    delay: 6000,
                };
                listenerApi.dispatch(addAppToast(toast));
                listenerApi.dispatch(popActiveScreen());
            } else {
                if (action.payload?.token) {
                    listenerApi.dispatch(
                        appServiceApi.endpoints.getVenues.initiate(void 0, {
                            forceRefetch: true,
                        })
                    );
                    listenerApi.dispatch(
                        appServiceApi.endpoints.getProfile.initiate(void 0, {
                            forceRefetch: true,
                        })
                    );
                    listenerApi.dispatch(setBearerToken(action.payload.token));
                }
                listenerApi.dispatch(showRoute("setPasswordSuccess"));
            }
            listenerApi.dispatch(setServerError(["password", void 0]));
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.setPassword.matchRejected,
        effect: (action, listenerApi) => {
            console.log("Password failed to set: %o", action.payload);
            const error = getServerError(action);
            listenerApi.dispatch(setServerError(["password", error]));
        },
    });
};

const listeners = [startSetPasswordListening];
export default listeners;
