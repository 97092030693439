import {
    configureStore,
    combineReducers,
    ThunkAction,
    Action,
} from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    createMigrate,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reportException } from "reportHandler";
import appSliceReducer from "./appSlice";
import loginTokenSlice from "features/loginToken/loginTokenSlice";
import tasksReducer from "features/tasks/tasksSlice";
import staffReducer from "features/staff/staffSlice";
import suppliersReducer from "features/suppliers/suppliersSlice";
import envReducer from "features/environment/envSlice";
import venueReducer from "features/venue/venueSlice";
import { envApi } from "services/env";
import { appServiceApi } from "services/appService";
import { authServiceApi } from "services/authService";
import { listenerMiddleware } from "./listeners";
import RedundantStore from "features/dataRedundancy/RedundantStore";
import IDBRedundantStore from "features/dataRedundancy/IDBRedundantStore";
import { isTesting } from "./util";

const rootReducer = combineReducers({
    app: appSliceReducer,
    loginToken: loginTokenSlice,
    tasks: tasksReducer,
    staff: staffReducer,
    suppliers: suppliersReducer,
    environment: envReducer,
    venue: venueReducer,
    [envApi.reducerPath]: envApi.reducer,
    [appServiceApi.reducerPath]: appServiceApi.reducer,
    [authServiceApi.reducerPath]: authServiceApi.reducer,
});

const migrations = {
    1: (state: any) => {
        return state;
    },
    2: (state: any) => {
        return {
            ...state,
            tasks: {
                ...state.tasks,
                taskDiaryEntries: {},
            },
        };
    },
    3: (state: any) => {
        return {
            ...state,
            app: {
                ...state.app,
                serverErrors: {},
            },
        };
    },
    4: (state: any) => {
        return {
            ...state,
            app: {
                ...state.app,
                diaryFileQueue: [],
            },
        };
    },
    6: (state: any) => {
        return {
            ...state,
            app: {
                ...state.app,
                networkQueue: {},
            },
        };
    },
};

const persistConfig = {
    key: "root",
    version: 6,
    storage,
    blacklist: [appServiceApi.reducerPath, envApi.reducerPath],
    migrate: createMigrate(migrations, { debug: false }),
    onWriteFail: (error: Error) => {
        console.error("Error writing to storage: %o", error);
        reportException("redux-persist onWriteFail", error);
    },
    deserialize: (serializedData: string) => {
        try {
            return JSON.parse(serializedData);
        } catch (e) {
            let message = "<unknown>";
            if (e instanceof Error) {
                message = e.message;
            }
            throw new Error(
                "Deserialize error: " + message + "; State: " + serializedData
            );
        }
    },
    debug: true,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = (addListeners?: boolean) => {
    const store = configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) => {
            let defaultMiddleware = getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [
                        FLUSH,
                        REHYDRATE,
                        PAUSE,
                        PERSIST,
                        PURGE,
                        REGISTER,
                    ],
                },
            }).concat(
                appServiceApi.middleware,
                envApi.middleware,
                authServiceApi.middleware
            );
            if (addListeners !== false) {
                defaultMiddleware = defaultMiddleware.prepend(
                    listenerMiddleware.middleware
                ) as unknown as typeof defaultMiddleware;
            }

            return defaultMiddleware;
        },
    });

    return store;
};

const addListeners = !isTesting();
let _store = setupStore(addListeners);

export function getStore(): ReturnType<typeof setupStore> {
    return _store;
}

export function setStore(store: ReturnType<typeof setupStore>) {
    _store = store;
}

let _persistor = persistStore(getStore());
export function getPersistor(
    store?: ReturnType<typeof setupStore>
): ReturnType<typeof persistStore> {
    if (store) {
        return persistStore(store);
    }
    return _persistor;
}

let _redundantStore = new IDBRedundantStore();

export function getRedundantStore(): RedundantStore {
    return _redundantStore;
}

export function setRedundantStore(store: RedundantStore) {
    _redundantStore = store;
}

export type AppDispatch = typeof _store.dispatch;
export type RootState = ReturnType<typeof _store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
