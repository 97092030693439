import styles from "./InviteProfile.module.scss";

export default function InviteProfileSuccess() {
    return (
        <div className={styles.InviteProfile}>
            <div className={styles.logoContainer}>
                <img
                    src="/Chomp-Logo-simplified.png"
                    alt="Chomp"
                    className={styles.logo}
                />
            </div>
            <h2>Thank you</h2>
            <p>Success message goes here.</p>
        </div>
    );
}
